// Generated by Framer (1249b65)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["X5YHJSE4W"];

const variantClassNames = {X5YHJSE4W: "framer-v-452loj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "X5YHJSE4W", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-XLow5", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-452loj", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"X5YHJSE4W"} ref={ref} style={{...style}}><SVG className={"framer-b4qyu0"} data-framer-name={"Frame_1000002131"} fill={"black"} intrinsicHeight={401} intrinsicWidth={356} layoutDependency={layoutDependency} layoutId={"a9y7WtlcA"} svg={"<svg width=\"356\" height=\"401\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><g opacity=\".7\" clip-path=\"url(#a)\"><circle cx=\"268.009\" cy=\"16.495\" r=\"4.091\" stroke=\"#2F83DE\"/><circle cx=\"351.409\" cy=\"146.405\" r=\"4.091\" stroke=\"#2F83DE\"/><circle opacity=\".9\" cx=\"13.486\" cy=\"286.513\" r=\"2.009\" fill=\"#2F83DE\"/><circle cx=\"28.121\" cy=\"263.844\" r=\"4.091\" stroke=\"#2F83DE\"/><circle cx=\"111.521\" cy=\"393.754\" r=\"4.091\" stroke=\"#2F83DE\"/><circle opacity=\".2\" cx=\"2.583\" cy=\"3.583\" r=\"2.583\" fill=\"#2F83DE\"/><circle opacity=\".2\" cx=\"85.982\" cy=\"133.493\" r=\"2.583\" fill=\"#2F83DE\"/><circle opacity=\".2\" cx=\"288.252\" cy=\"372.835\" r=\"2.583\" fill=\"#2F83DE\"/><circle opacity=\".9\" cx=\"154.665\" cy=\"34.573\" r=\"2.009\" fill=\"#2F83DE\"/><circle opacity=\".9\" cx=\"238.064\" cy=\"164.483\" r=\"2.009\" fill=\"#2F83DE\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" transform=\"translate(0 .718)\" d=\"M0 0h356v400H0z\"/></clipPath></defs></svg>"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-XLow5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XLow5 .framer-12n81ml { display: block; }", ".framer-XLow5 .framer-452loj { height: 2922px; overflow: hidden; position: relative; width: 2594px; }", ".framer-XLow5 .framer-b4qyu0 { aspect-ratio: 0.8877805486284289 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 2922px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2922
 * @framerIntrinsicWidth 2594
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const Framerc58kz9o9C: React.ComponentType<Props> = withCSS(Component, css, "framer-XLow5") as typeof Component;
export default Framerc58kz9o9C;

Framerc58kz9o9C.displayName = "Frame_1000002131";

Framerc58kz9o9C.defaultProps = {height: 2922, width: 2594};

addFonts(Framerc58kz9o9C, [])